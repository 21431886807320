import { $, addAction, INIT } from '@situation/setdesign.util';

const iconTemplate = (name) => `<span class="icon icon-${name}"><svg><use class="icon-use" xlink:href="#icon-${name}"></use></svg></span>`;

const getIconName = ($button) => {
    if ($button.find('span.icon').length || $button.hasClass('button--no-icon')) {
        return false;
    }
    if (($button.attr('href') || '').startsWith('mailto')) {
        return 'send';
    }
    return $button.attr('target') === '_blank' ? 'outbound' : 'arrow-right';
};

const appendButtonIcon = (button, iconName = null, removePrevious = false) => {
    const $button = $(button);
    if (removePrevious) {
        $button.find('span.icon').remove();
    }

    const newIconName = iconName || getIconName($button);
    if (newIconName) {
        $button.append(iconTemplate(newIconName));
    }
};

const decorateDownloadLinks = ($links) => {
    $links.each((i, el) => {
        const $link = $(el);
        const href = $link.attr('href');
        const fileExtensions = [
            'pdf',
            'jpg',
            'jpeg',
            'png',
            'gif',
            'doc',
            'docx',
            'xls',
            'xlsx',
            'ppt',
            'pptx',
            'txt',
        ];
        const regexPattern = `\\.(${fileExtensions.join('|')})$`;
        if (href && href.match(new RegExp(regexPattern, 'i'))) {
            if (!$link.hasClass('button')) {
                $link.addClass('has-icon');
            }

            appendButtonIcon($link, 'download');
        }
    });
};

const groupButtons = () => {
    document
        .querySelectorAll('.content-section .button, .search-form .button, .footer-nav .button')
        .forEach((button) => {
            appendButtonIcon(button);

            if (
                button.classList.contains('button--no-group')
                || button.parentElement.classList.contains('button-group')
            ) {
                return;
            }

            const adjacentButtons = [button];
            let nextSibling = button.nextElementSibling;

            while (nextSibling && nextSibling.classList.contains('button')) {
                adjacentButtons.push(nextSibling);
                nextSibling = nextSibling.nextElementSibling;
            }

            const wrapper = document.createElement('div');
            wrapper.className = 'button-group col-reset-exclude';
            adjacentButtons[0].before(wrapper);
            adjacentButtons.forEach((btn) => wrapper.appendChild(btn));
        });
};

addAction(INIT, () => {
    decorateDownloadLinks($('.content-section [href]'));
    groupButtons();
});

addAction('button-icon', appendButtonIcon);

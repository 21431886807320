import { addFilter, doAction } from '@situation/setdesign.util';

const handleCarouselChange = (e) => {
    if (e.property.name === 'position') {
        doAction('videoPause');
    }
};

const modifySettings = (settings, $instance) => {
    if ($instance.hasClass('media-slider')) {
        $instance.on('changed.owl.carousel', handleCarouselChange);
        return {
            ...settings,
            items: 1,
            lazyLoad: true,
            loop: true,
            margin: 15,
            autoHeight: true,
            responsive: { 768: { autoHeight: false } },
        };
    }
    return settings;
};

addFilter('content-slider/owl-settings', modifySettings);

import {
    $, addAction, doAction, LAYOUT, INIT,
} from '@situation/setdesign.util';

const owlSettings = {
    loop: true,
    items: 1,
    stagePadding: 20,
    nav: false,
    dots: false,
    responsive: { 600: { items: 2 } },
};
let initialized = false;
addAction(INIT, () => {
    const $rows = $('.content-row--slider-mobile');
    if (!$rows.length) {
        return;
    }

    const updateSlider = (widthHasChanged) => {
        if (widthHasChanged) {
            if (SetDesign.viewport.width() < 768 && !initialized) {
                initialized = true;
                $rows.addClass('owl-carousel').owlCarousel(owlSettings);
            } else if (SetDesign.viewport.width() >= 768 && initialized) {
                initialized = false;
                $rows.removeClass('owl-carousel').trigger('destroy.owl.carousel');
            }
        }
    };

    $rows.on('changed.owl.carousel', (e) => {
        if (e.property.name === 'position') {
            doAction('refreshLazyLoad', $(e.currentTarget));
        }
    });

    addAction(LAYOUT, updateSlider, 5);
});

import { $, addAction, INIT } from '@situation/setdesign.util';

const assignDataLabel = () => {
    $('.responsive-table').each((i, tableElement) => {
        const $table = $(tableElement);
        const headers = [];
        $table.find('th').each((_, headerElement) => {
            headers.push($(headerElement).text());
        });

        $table.find('tr').each((_, rowElement) => {
            $(rowElement)
                .find('td')
                .each((index, cellElement) => {
                    $(cellElement).attr('data-label', headers[index]);
                });
        });
    });
};

addAction(INIT, assignDataLabel);

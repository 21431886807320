import { $, addAction, INIT } from '@situation/setdesign.util';

const toggleColumnClass = ($container, toggle) => $container.closest('.content-column').toggleClass('content-column--video-playing', toggle);

const fillContainer = (i, el) => {
    const $video = $(el);
    const videoId = $video.data('videoId');
    const imageUrl = window.videoCache[videoId]?.img.src || false;
    if (imageUrl) {
        const $contentBgImage = $('<span>', { class: 'content-background-image' }).append(
            $('<span>', { class: 'content-background-image__images' }).append(
                $('<span>', {
                    class: 'content-background-image__img content-background-image__img--desktop',
                    'data-lazy-bg': imageUrl,
                }),
            ),
        );
        const $column = $video.closest('.content-column');
        $column
            .addClass('content-column--has-bg content-column--no-gutter--xs align-items-center')
            .children('.content-background-image')
            .remove();

        $column
            .children('.content-column__inner')
            .addClass(
                'content-column__inner--no-gutter--xs content-column__inner--hpad-xs-none content-column__inner--hpad-none',
            );

        $column.prepend($contentBgImage);
    }
};

addAction('video.playing', (video, $container) => toggleColumnClass($container, true));
addAction(INIT, () => $('.video-thumb--fill-container').each(fillContainer), 5);

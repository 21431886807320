import {
    $, addAction, INIT, LAYOUT,
} from '@situation/setdesign.util';

const initAction = () => {
    const $verticalTeaserCards = $('.vertical-teaser-card');
    if (!$verticalTeaserCards.length) return;

    const layoutAction = () => {
        $verticalTeaserCards.each((_, el) => {
            const $card = $(el);
            const buttonHeight = $card.find('.button-group').outerHeight();
            const padding = parseInt(
                $card.find('.vertical-teaser-card__content').css('padding-bottom'),
                10,
            );
            const offset = (buttonHeight + padding) * -1;
            $card.css('--teaser-card-offset', `${offset}px`);
        });
    };

    addAction(LAYOUT, layoutAction);
};

addAction(INIT, initAction);

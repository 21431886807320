import { $ } from '@situation/setdesign.util';

const resetBodyPadding = () => {
    $('body').css('padding-top', '');
};

$(window).on('CookiebotOnDialogDisplay', () => {
    setTimeout(resetBodyPadding, 0);
});

if (window.CookieConsentDialog) {
    resetBodyPadding();
}

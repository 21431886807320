import {
    $, addAction, doAction, INIT,
} from '@situation/setdesign.util';

const sliderConfig = {
    items: 1,
    dots: false,
    nav: true,
    margin: 15,
    navText: [
        '<svg><use xlink:href="#icon-arrow-right"></use></svg>',
        '<svg><use xlink:href="#icon-arrow-right"></use></svg>',
    ],
    responsive: { 600: { items: 2 }, 768: { items: 3, margin: 20 } },
};

const onSlideChange = (event) => {
    if (event.property.name === 'position') {
        const $slider = $(event.target);
        $slider
            .find('.owl-item')
            .removeClass('owl-item--previous')
            .slice(0, event.property.value)
            .addClass('owl-item--previous');

        doAction('refreshLazyLoad', $slider);
    }
};

addAction(INIT, () => $('.news-slider').on('changed.owl.carousel', onSlideChange).owlCarousel(sliderConfig));

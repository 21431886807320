import {
    $, addAction, INIT, LAYOUT,
} from '@situation/setdesign.util';

const flexDuoElements = '.vc-single-image, .video-thumb, .motion-clip, .media-slider';

const pullUpRows = ($allRows) => {
    const contentRowPairs = $allRows
        .filter('.content-row--next-row-pull-up')
        .map((_, element) => {
            const $current = $(element);
            const $next = $allRows.eq($allRows.index($current) + 1);
            return { $current, $next };
        })
        .get();
    const layoutAction = () => contentRowPairs.forEach(({ $current, $next }) => {
        if ($next.length) {
            $next.css('margin-top', '');

            if ($current.height()) {
                const offsetDiff = Math.round($current.offset().top - $next.offset().top);
                $next.css('margin-top', `${offsetDiff}px`);
            }
        }
    });
    if (contentRowPairs.length) {
        addAction(LAYOUT, layoutAction);
    }
};

const reverseRows = ($allRows) => $allRows
    .filter((_, el) => {
        const $columns = $(el).find('.content-column');
        return (
            $columns.length === 2
                && $columns.eq(0).find('.heading, .column-text').length
                && $columns.eq(1).find(flexDuoElements).length
        );
    })
    .addClass('content-row--row-reverse-mobile');

const flexDuoSections = (_, el) => {
    const $section = $(el);
    const $row = $section.find('.content-row:first');
    if (
        $row.find('.content-column').length === 2
        && $row.find('.content-column--no-gutter--xs').find(flexDuoElements).length
    ) {
        $section.addClass('content-section--flex-duo');
    }
};

addAction(INIT, () => {
    const $allRows = $('.content-row');
    pullUpRows($allRows);
    reverseRows($allRows.filter(':not(.content-row--no-reverse)'));
    $('.content-section').each(flexDuoSections);
});

import { $, addAction, INIT } from '@situation/setdesign.util';

const initAction = () => {
    const $allMenuSidebars = $('.menu-sidebar');
    if (!$allMenuSidebars.length) {
        return;
    }

    const OPEN_CLASS = 'open';
    const HAS_CHILDREN_CLASS = 'menu-item-has-children';
    const { href } = window.location;
    const $targetMenuItem = $allMenuSidebars.find(`a[href="${href}"]`);
    if ($targetMenuItem) {
        $targetMenuItem.each((index, value) => {
            $(value).parents(`.${HAS_CHILDREN_CLASS}`).addClass(OPEN_CLASS);
        });
    }

    $allMenuSidebars.on('click', '.menu-item-has-children > a', (e) => {
        const $currentTarget = $(e.currentTarget);
        const $parent = $currentTarget.parent();
        if ($currentTarget.attr('href') === '#') {
            e.preventDefault();
        }

        $parent.toggleClass(OPEN_CLASS, !$parent.hasClass(OPEN_CLASS));
    });
};

addAction(INIT, initAction);

import { $, addAction, INIT } from '@situation/setdesign.util';

const defaultBG = 'content-section--bg-body-background';
const alternateBG = 'content-section--bg-white';
let previousClass = '';
let followsCutoutBorder = false;

const getVisibleSections = (i, el) => {
    const $element = $(el);
    if (!$element.is(':visible')) {
        return false;
    }

    const $tab = $element.closest('[data-toggle-target]');
    return !$tab.length || $tab.hasClass('toggle-active');
};

const setSectionBg = (i, el) => {
    const $container = $(el);
    $container.toggleClass('content-section--book-bottom-adjacent', followsCutoutBorder);

    if (
        $container.hasClass('content-section--bg-contrast')
        || $container.hasClass('content-section--bg-match')
    ) {
        $container.removeClass([defaultBG, alternateBG]);
    }
    if ($container.hasClass(defaultBG) || $container.hasClass(alternateBG)) {
        previousClass = $container.hasClass(defaultBG) ? defaultBG : alternateBG;
    }
    if ($container.hasClass('content-section--bg-contrast')) {
        const newClass = previousClass === defaultBG ? alternateBG : defaultBG;
        $container.addClass(newClass);
        previousClass = newClass;
    } else if ($container.hasClass('content-section--bg-match')) {
        $container.addClass(previousClass);
    }

    followsCutoutBorder = $container.hasClass('content-section--book-bottom');
};

const configureContrastChild = (i, el) => {
    const $container = $(el);
    $container.css({ '--current-container-bg': '', '--current-contrast-bg': '' });
    const currentBG = $container.css('--current-container-bg');
    const currentContrast = $container.css('--current-contrast-bg');
    $container.css({
        '--current-container-bg': currentContrast,
        '--current-contrast-bg': currentBG,
    });
};

addAction(INIT, () => {
    const $sections = $('.content-section');
    const $contrastRows = $sections.find('.content-row--bg-contrast');
    const $contrastColumns = $sections.find('.content-column--bg-contrast');

    const setContrast = () => {
        previousClass = '';
        followsCutoutBorder = false;
        $sections.filter(getVisibleSections).each(setSectionBg);
        $contrastRows.each(configureContrastChild);
        $contrastColumns.each(configureContrastChild);
    };

    setContrast();
    addAction('toggleActive', setContrast);
});

import {
    $, addAction, doAction, INIT,
} from '@situation/setdesign.util';

const handleFormSubmit = ($form) => {
    const $submitButton = $form.find('button');
    $submitButton.attr('disabled', true);
    doAction('button-icon', $submitButton, 'loading', true);
};

const handleToggleClick = ($form, $toggle) => {
    const isOpen = $form.hasClass('toggle-active');
    $form.toggleClass('toggle-active');
    doAction('button-icon', $toggle, isOpen ? 'filter' : 'close', true);
};

const toggleEraClass = (e) => {
    const programmeValue = $(e.target).val();
    const $era = $('.search-filters__form #search-filter-era');
    $era.toggleClass(
        'search-filters__select--disabled',
        programmeValue !== '' && programmeValue !== 'university-fellow',
    );
};

addAction(INIT, () => {
    const $form = $('.search-filters__form');
    const $toggle = $('.search-filters__toggle');
    const $programme = $form.find('#search-filter-programme');
    if ($programme.length) {
        $programme.on('change', toggleEraClass);
        toggleEraClass({ target: $programme });
    }

    $form.on('submit', (e) => {
        handleFormSubmit($(e.currentTarget));
    });

    $toggle.on('click', (e) => {
        handleToggleClick($form, $(e.currentTarget));
    });
});

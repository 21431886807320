import { $, addAction, INIT } from '@situation/setdesign.util';

const initAction = () => {
    const $resourceCategoryMenu = $('#resource-category-menu');
    if (!$resourceCategoryMenu.length) {
        return;
    }

    const $trigger = $('#menu-sidebar-trigger');
    $trigger.on('click', (e) => {
        e.preventDefault();
        $resourceCategoryMenu.toggleClass('d-none', !$resourceCategoryMenu.hasClass('d-none'));
    });
};

addAction(INIT, initAction);

import { $, addAction } from '@situation/setdesign.util';

const handleMessage = (e) => {
    if (e.data.type === 'updateHeight') {
        const $frame = $(`iframe[src="${e.data.path}"]`);
        $frame.parent().removeClass('loading');
        $frame.height(`${e.data.height}px`);
    }
};

window.addEventListener('message', handleMessage);
addAction('showModal', ($modal) => {
    $modal.find('[data-deferred-content]').each((i, el) => {
        const $this = $(el);
        const decoded = atob($this.data('deferredContent'));
        el.removeAttribute('data-deferred-content');
        $this.html(decoded);
    });
});

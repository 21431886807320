import { doAction, addAction, INIT } from '@situation/setdesign.util';

const refreshCssVars = () => doAction('css-vars/refresh');
addAction('stickyHeaderShow', refreshCssVars, 20);
addAction('stickyHeaderHide', refreshCssVars, 20);
addAction(INIT, () => {
    const header = document.getElementById('header');
    if (!header) {
        return;
    }

    const headerSearchButton = document.getElementById('header__search-button');
    const headerSearchDrawer = document.getElementsByClassName('header__search-drawer').item('0');
    const navMenuTopLevelLinks = header.querySelectorAll('.nav-menu__list > .nav-menu__item > a');
    if (!navMenuTopLevelLinks || !headerSearchButton) return;

    const ACTIVE_CLASS = 'active';

    const removeNavMenuActiveState = () => {
        navMenuTopLevelLinks.forEach((link) => {
            if (link.parentNode.classList.contains(ACTIVE_CLASS)) {
                link.parentNode.classList.remove(ACTIVE_CLASS);
            }
        });

        if (headerSearchDrawer.classList.contains(ACTIVE_CLASS)) {
            headerSearchDrawer.classList.remove(ACTIVE_CLASS);
        }
    };

    headerSearchButton.addEventListener('click', () => {
        if (headerSearchDrawer.classList.contains(ACTIVE_CLASS)) {
            removeNavMenuActiveState();
        } else {
            removeNavMenuActiveState();
            headerSearchDrawer.classList.add(ACTIVE_CLASS);
            const searchInput = document.querySelectorAll('.drawer__container .search-field');
            if (searchInput.length && searchInput[0].focus) {
                searchInput[0].focus();
            }
        }
    });

    navMenuTopLevelLinks.forEach((item) => {
        const drawerWrapHeading = item.parentNode.querySelector('.nav-menu__heading');
        const drawerWrapDescription = item.parentNode.querySelector('.nav-menu__description');
        item.addEventListener('click', (event) => {
            if (event.currentTarget.parentNode.classList.contains(ACTIVE_CLASS)) {
                removeNavMenuActiveState();
            } else {
                removeNavMenuActiveState();
                event.currentTarget.parentNode.classList.add(ACTIVE_CLASS);
                const parent = event.currentTarget.parentNode;
                drawerWrapHeading.innerHTML = parent.getAttribute('data-heading');
                const description = parent.getAttribute('data-description');
                drawerWrapDescription.innerHTML = description || '';
            }
        });
    });

    document.addEventListener('click', (event) => {
        if (!header.contains(event.target)) {
            removeNavMenuActiveState();
        }
    });

    document.onkeydown = (e) => {
        let isEscape = false;
        if ('key' in e) {
            isEscape = e.key === 'Escape' || e.key === 'Esc';
        } else {
            isEscape = e.keyCode === 27;
        }
        if (isEscape) {
            removeNavMenuActiveState();
        }
    };
});

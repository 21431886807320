import { $, addAction, INIT } from '@situation/setdesign.util';

addAction(INIT, () => {
    if (!window.Plyr) {
        return;
    }

    $('audio').each((_, el) => {
        // eslint-disable-next-line no-new
        new window.Plyr(el);
    });
});
